import { isClient } from "@tamagui/constants";
import * as React from "react";
import { ComponentContext } from "../contexts/ComponentContext.mjs";
import { setupMediaListeners } from "../hooks/useMedia.mjs";
import { ThemeProvider } from "./ThemeProvider.mjs";
import { jsx } from "react/jsx-runtime";
function TamaguiProvider({
  children,
  disableInjectCSS,
  config,
  ...themePropsProvider
}) {
  return setupMediaListeners(), isClient && React.useLayoutEffect(() => {
    if (config && (config.disableSSR || document.documentElement.classList.contains("t_unmounted") && document.documentElement.classList.remove("t_unmounted"), !disableInjectCSS)) {
      const style = document.createElement("style");
      return style.appendChild(document.createTextNode(config.getCSS())), document.head.appendChild(style), () => {
        document.head.removeChild(style);
      };
    }
  }, [config, disableInjectCSS]), /* @__PURE__ */jsx(ComponentContext.Provider, {
    animationDriver: config?.animations,
    children: /* @__PURE__ */jsx(ThemeProvider, {
      themeClassNameOnRoot: config?.themeClassNameOnRoot,
      disableRootThemeClass: config?.disableRootThemeClass,
      ...themePropsProvider,
      defaultTheme: themePropsProvider.defaultTheme ?? (config ? Object.keys(config.themes)[0] : ""),
      children
    })
  });
}
TamaguiProvider.displayName = "TamaguiProvider";
export { TamaguiProvider };