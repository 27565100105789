var LazyServiceIdentifier = (function () {
    function LazyServiceIdentifier(cb) {
        this._cb = cb;
    }
    LazyServiceIdentifier.prototype.unwrap = function () {
        return this._cb();
    };
    return LazyServiceIdentifier;
}());
export { LazyServiceIdentifier };
