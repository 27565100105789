import { composeRefs } from "@tamagui/compose-refs";
import { isClient, isServer, isWeb } from "@tamagui/constants";
import { composeEventHandlers, validStyles } from "@tamagui/helpers";
import { useDidFinishSSR } from "@tamagui/use-did-finish-ssr";
import React, { Children, Fragment, createElement, forwardRef, memo, useContext, useEffect, useId, useMemo, useRef, useState } from "react";
import { devConfig, getConfig, onConfiguredOnce } from "./config.mjs";
import { stackDefaultStyles } from "./constants/constants.mjs";
import { ComponentContext } from "./contexts/ComponentContext.mjs";
import { didGetVariableValue, setDidGetVariableValue } from "./createVariable.mjs";
import { defaultComponentState, defaultComponentStateMounted, defaultComponentStateShouldEnter } from "./defaultComponentState.mjs";
import { createShallowSetState, mergeIfNotShallowEqual } from "./helpers/createShallowSetState.mjs";
import { useSplitStyles } from "./helpers/getSplitStyles.mjs";
import { isObj } from "./helpers/isObj.mjs";
import { log } from "./helpers/log.mjs";
import { mergeProps } from "./helpers/mergeProps.mjs";
import { themeable } from "./helpers/themeable.mjs";
import { mediaKeyMatch, setMediaShouldUpdate, useMedia } from "./hooks/useMedia.mjs";
import { useThemeWithState } from "./hooks/useTheme.mjs";
import { hooks } from "./setupHooks.mjs";
import { Slot } from "./views/Slot.mjs";
import { getThemedChildren } from "./views/Theme.mjs";
import { ThemeDebug } from "./views/ThemeDebug.mjs";
import { isDevTools } from "./constants/isDevTools.mjs";
import { jsx } from "react/jsx-runtime";
let tamaguiConfig, time, debugKeyListeners, startVisualizer;
const mouseUps = /* @__PURE__ */new Set();
if (typeof document < "u") {
  const cancelTouches = () => {
    mouseUps.forEach(x => x()), mouseUps.clear();
  };
  addEventListener("mouseup", cancelTouches), addEventListener("touchend", cancelTouches), addEventListener("touchcancel", cancelTouches), process.env.NODE_ENV === "development" && (startVisualizer = () => {
    const devVisualizerConfig = devConfig?.visualizer;
    if (devVisualizerConfig) {
      debugKeyListeners = /* @__PURE__ */new Set();
      let tm,
        isShowing = !1;
      const options = {
        key: "Alt",
        delay: 800,
        ...(typeof devVisualizerConfig == "object" ? devVisualizerConfig : {})
      };
      document.addEventListener("blur", () => {
        clearTimeout(tm);
      }), document.addEventListener("keydown", ({
        key,
        defaultPrevented
      }) => {
        defaultPrevented || (clearTimeout(tm), key === options.key && (tm = setTimeout(() => {
          isShowing = !0, debugKeyListeners?.forEach(l => l(!0));
        }, options.delay)));
      }), document.addEventListener("keyup", ({
        key,
        defaultPrevented
      }) => {
        defaultPrevented || key === options.key && (clearTimeout(tm), isShowing && debugKeyListeners?.forEach(l => l(!1)));
      });
    }
  });
}
const useComponentState = (props, {
  animationDriver,
  groups
}, staticConfig, config) => {
  const useAnimations = animationDriver?.useAnimations,
    stateRef = useRef({}),
    hasAnimationProp = !!("animation" in props || props.style && hasAnimatedStyleValue(props.style)),
    supportsCSSVars = animationDriver?.supportsCSSVars,
    curStateRef = stateRef.current,
    willBeAnimatedClient = !!(!!(hasAnimationProp && !staticConfig.isHOC && useAnimations) || curStateRef.hasAnimated),
    willBeAnimated = !isServer && willBeAnimatedClient;
  willBeAnimated && !curStateRef.hasAnimated && (curStateRef.hasAnimated = !0);
  const isHydrated = config?.disableSSR ? !0 : useDidFinishSSR(),
    presence = willBeAnimated && props.animatePresence !== !1 && animationDriver?.usePresence?.() || null,
    presenceState = presence?.[2],
    isExiting = presenceState?.isPresent === !1,
    isEntering = presenceState?.isPresent === !0 && presenceState.initial !== !1,
    hasEnterStyle = !!props.enterStyle,
    hasRNAnimation = hasAnimationProp && animationDriver?.isReactNative,
    isReactNative = staticConfig.isReactNative;
  let isAnimated = willBeAnimated;
  !isReactNative && hasRNAnimation && !staticConfig.isHOC && !isHydrated && (isAnimated = !1, curStateRef.willHydrate = !0), process.env.NODE_ENV === "development" && time && time`pre-use-state`;
  const hasEnterState = hasEnterStyle || isEntering,
    needsToMount = !isHydrated || !curStateRef.host,
    initialState = hasEnterState ? needsToMount ? defaultComponentStateShouldEnter : defaultComponentState : defaultComponentStateMounted,
    disabled = isDisabled(props);
  disabled != null && (initialState.disabled = disabled);
  const states = useState(initialState),
    state = props.forceStyle ? {
      ...states[0],
      [props.forceStyle]: !0
    } : states[0],
    setState = states[1];
  disabled !== state.disabled && (state.disabled = disabled, disabled && Object.assign(state, defaultComponentStateMounted), setState({
    ...state
  }));
  let setStateShallow = createShallowSetState(setState, disabled, props.debug);
  if (isHydrated && state.unmounted === "should-enter" && (state.unmounted = !0), presenceState && isAnimated && isHydrated && staticConfig.variants) {
    process.env.NODE_ENV === "development" && props.debug === "verbose" && console.warn(`has presenceState ${JSON.stringify(presenceState)}`);
    const {
      enterVariant,
      exitVariant,
      enterExitVariant,
      custom
    } = presenceState;
    isObj(custom) && Object.assign(props, custom);
    const exv = exitVariant ?? enterExitVariant,
      env = enterVariant ?? enterExitVariant;
    state.unmounted && env && staticConfig.variants[env] ? (process.env.NODE_ENV === "development" && props.debug === "verbose" && console.warn(`Animating presence ENTER "${env}"`), props[env] = !0) : isExiting && exv && (process.env.NODE_ENV === "development" && props.debug === "verbose" && console.warn(`Animating presence EXIT "${exv}"`), props[exv] = exitVariant !== enterExitVariant);
  }
  const shouldAvoidClasses = !!(!isWeb || isAnimated && !supportsCSSVars || !staticConfig.acceptsClassName ||
    // on server for SSR and animation compat added the && isHydrated but perhaps we want
    // disableClassName="until-hydrated" to be more straightforward
    // see issue if not, Button sets disableClassName to true <Button animation="" /> with
    // the react-native driver errors because it tries to animate var(--color) to rbga(..)
    props.disableClassName && isHydrated),
    groupName = props.group;
  if (groupName && !curStateRef.group) {
    const listeners = /* @__PURE__ */new Set();
    curStateRef.group = {
      listeners,
      emit(name, state2) {
        listeners.forEach(l => l(name, state2));
      },
      subscribe(cb) {
        return listeners.add(cb), () => {
          listeners.delete(cb);
        };
      }
    };
  }
  if (groupName) {
    const groupContextState = groups.state,
      og = setStateShallow;
    setStateShallow = state2 => {
      og(state2), curStateRef.group.emit(groupName, {
        pseudo: state2
      });
      const next = {
        ...groupContextState[groupName],
        ...state2
      };
      groupContextState[groupName] = next;
    };
  }
  return {
    curStateRef,
    disabled,
    groupName,
    hasAnimationProp,
    hasEnterStyle,
    isAnimated,
    isExiting,
    isHydrated,
    presence,
    presenceState,
    setState,
    setStateShallow,
    shouldAvoidClasses,
    state,
    stateRef,
    supportsCSSVars,
    willBeAnimated,
    willBeAnimatedClient
  };
};
let BaseText, BaseView;
const lastInteractionWasKeyboard = {
  value: !1
};
isWeb && globalThis.document && (document.addEventListener("keydown", () => {
  lastInteractionWasKeyboard.value = !0;
}), document.addEventListener("mousedown", () => {
  lastInteractionWasKeyboard.value = !1;
}), document.addEventListener("mousemove", () => {
  lastInteractionWasKeyboard.value = !1;
}));
function createComponent(staticConfig) {
  const {
    componentName
  } = staticConfig;
  let config = null,
    defaultProps = staticConfig.defaultProps;
  onConfiguredOnce(conf => {
    if (config = conf, componentName) {
      const defaultForComponent = conf.defaultProps?.[componentName];
      defaultForComponent && (defaultProps = {
        ...defaultForComponent,
        ...defaultProps
      });
    }
  });
  const {
    Component,
    isText,
    isZStack,
    isHOC,
    validStyles: validStyles2 = {},
    variants = {}
  } = staticConfig;
  process.env.NODE_ENV === "development" && staticConfig.defaultProps?.debug && process.env.IS_STATIC !== "is_static" && log(`\u{1F41B} [${componentName || "Component"}]`, {
    staticConfig,
    defaultProps,
    defaultPropsKeyOrder: defaultProps ? Object.keys(defaultProps) : []
  });
  const component = forwardRef((propsIn, forwardedRef) => {
    const internalID = process.env.NODE_ENV === "development" ? useId() : "";
    process.env.NODE_ENV === "development" && startVisualizer && (startVisualizer(), startVisualizer = void 0), process.env.NODE_ENV === "test" && propsIn["data-test-renders"] && (propsIn["data-test-renders"].current ??= 0, propsIn["data-test-renders"].current += 1);
    const componentContext = useContext(ComponentContext);
    let styledContextProps, overriddenContextProps, contextValue;
    const {
      context,
      isReactNative
    } = staticConfig;
    if (context) {
      contextValue = useContext(context);
      const {
        inverseShorthands
      } = getConfig();
      for (const key in context.props) {
        const propVal =
        // because its after default props but before props this annoying amount of checks
        propsIn[key] ?? propsIn[inverseShorthands[key]] ?? defaultProps?.[key] ?? defaultProps?.[inverseShorthands[key]];
        propVal === void 0 ? contextValue && (key in validStyles2 || key in variants) && (styledContextProps ||= {}, styledContextProps[key] = contextValue[key]) : (overriddenContextProps ||= {}, overriddenContextProps[key] = propVal);
      }
    }
    const curDefaultProps = styledContextProps ? {
      ...defaultProps,
      ...styledContextProps
    } : defaultProps;
    let props = propsIn;
    curDefaultProps && (props = mergeProps(curDefaultProps, propsIn));
    const debugProp = props.debug,
      componentName2 = props.componentName || staticConfig.componentName;
    process.env.NODE_ENV === "development" && isClient && useEffect(() => {
      let overlay = null;
      const debugVisualizerHandler = (show = !1) => {
        const node = curStateRef.host;
        if (node) if (show) {
          overlay = document.createElement("span"), overlay.style.inset = "0px", overlay.style.zIndex = "1000000", overlay.style.position = "absolute", overlay.style.borderColor = "red", overlay.style.borderWidth = "1px", overlay.style.borderStyle = "dotted";
          const dataAt = node.getAttribute("data-at") || "",
            dataIn = node.getAttribute("data-in") || "",
            tooltip = document.createElement("span");
          tooltip.style.position = "absolute", tooltip.style.top = "0px", tooltip.style.left = "0px", tooltip.style.padding = "3px", tooltip.style.background = "rgba(0,0,0,0.75)", tooltip.style.color = "rgba(255,255,255,1)", tooltip.style.fontSize = "12px", tooltip.style.lineHeight = "12px", tooltip.style.fontFamily = "monospace", tooltip.style.webkitFontSmoothing = "none", tooltip.innerText = `${componentName2 || ""} ${dataAt} ${dataIn}`.trim(), overlay.appendChild(tooltip), node.appendChild(overlay);
        } else overlay && node.removeChild(overlay);
      };
      return debugKeyListeners ||= /* @__PURE__ */new Set(), debugKeyListeners.add(debugVisualizerHandler), () => {
        debugKeyListeners?.delete(debugVisualizerHandler);
      };
    }, [componentName2]), !process.env.TAMAGUI_IS_CORE_NODE && process.env.NODE_ENV === "development" && debugProp === "profile" && !time && (time = require("@tamagui/timer").timer().start()), process.env.NODE_ENV === "development" && time && time`start (ignore)`, process.env.NODE_ENV === "development" && time && time`did-finish-ssr`, process.env.NODE_ENV === "development" && time && time`stateref`;
    const animationDriver = componentContext.animationDriver,
      useAnimations = animationDriver?.useAnimations,
      {
        curStateRef,
        disabled,
        groupName,
        hasAnimationProp,
        hasEnterStyle,
        isAnimated,
        isExiting,
        isHydrated,
        presence,
        presenceState,
        setState,
        setStateShallow,
        shouldAvoidClasses,
        state,
        stateRef,
        supportsCSSVars,
        willBeAnimated,
        willBeAnimatedClient
      } = useComponentState(props, componentContext, staticConfig, config),
      shouldForcePseudo = !!propsIn.forceStyle,
      noClassNames = shouldAvoidClasses || shouldForcePseudo;
    process.env.NODE_ENV === "development" && time && time`use-state`;
    const hasTextAncestor = !!(isWeb && isText && componentContext.inText);
    process.env.NODE_ENV === "development" && time && time`use-context`;
    const isTaggable = !Component || typeof Component == "string",
      tagProp = props.tag,
      element = isWeb && isTaggable && tagProp || Component;
    let elementType = isText ? BaseText || element || "span" : BaseView || element || (hasTextAncestor ? "span" : "div");
    animationDriver && isAnimated && (elementType = animationDriver[isText ? "Text" : "View"] || elementType);
    const disableTheme = props["data-disable-theme"] || isHOC;
    process.env.NODE_ENV === "development" && time && time`theme-props`, props.themeShallow && (curStateRef.themeShallow = !0);
    const themeStateProps = {
      name: props.theme,
      componentName: componentName2,
      disable: disableTheme,
      shallow: curStateRef.themeShallow,
      inverse: props.themeInverse,
      debug: debugProp
    };
    if (typeof curStateRef.isListeningToTheme == "boolean" && (themeStateProps.shouldUpdate = () => stateRef.current.isListeningToTheme), process.env.NODE_ENV === "development" && debugProp && debugProp !== "profile") {
      const name = `${componentName2 || Component?.displayName || Component?.name || "[Unnamed Component]"}`,
        type = (hasEnterStyle ? "(hasEnter)" : "") + (isAnimated ? "(animated)" : "") + (isReactNative ? "(rnw)" : "") + (presenceState?.isPresent === !1 ? "(EXIT)" : ""),
        dataIs = propsIn["data-is"] || "",
        banner = `${internalID} ${name}${dataIs ? ` ${dataIs}` : ""} ${type}`;
      if (console.info(`%c ${banner} (hydrated: ${isHydrated}) (unmounted: ${state.unmounted})`, "background: green; color: white;"), isServer) log({
        noClassNames,
        isAnimated,
        shouldAvoidClasses,
        isWeb,
        supportsCSSVars
      });else {
        console.groupEnd();
        const stateLog = `${`${state.press || state.pressIn ? " PRESS " : ""}`}${state.hover ? " HOVER " : ""}${state.focus ? " FOCUS" : " "}`,
          ch = propsIn.children;
        let childLog = typeof ch == "string" ? ch.length > 4 ? ch.slice(0, 4) + "..." : ch : "";
        childLog.length && (childLog = `(children: ${childLog})`), console.groupCollapsed(`${childLog}${stateLog}Props:`), log("props in:", propsIn), log("final props:", props), log({
          state,
          staticConfig,
          elementType,
          themeStateProps
        }), log({
          contextProps: styledContextProps,
          overriddenContextProps
        }), log({
          presence,
          isAnimated,
          isHOC,
          hasAnimationProp,
          useAnimations
        }), console.groupEnd();
      }
    }
    process.env.NODE_ENV === "development" && time && time`pre-theme-media`;
    const [themeState, theme] = useThemeWithState(themeStateProps);
    elementType = Component || elementType;
    const isStringElement = typeof elementType == "string";
    process.env.NODE_ENV === "development" && time && time`theme`;
    const mediaState = useMedia(stateRef, componentContext);
    setDidGetVariableValue(!1), process.env.NODE_ENV === "development" && time && time`media`;
    const resolveValues =
      // if HOC + mounted + has animation prop, resolve as value so it passes non-variable to child
      isAnimated && !supportsCSSVars || isHOC && state.unmounted == !1 && hasAnimationProp ? "value" : "auto",
      styleProps = {
        mediaState,
        noClassNames,
        resolveValues,
        isExiting,
        isAnimated
      },
      splitStyles = useSplitStyles(props, staticConfig, theme, themeState?.state?.name || "", state, styleProps, null, componentContext, elementType, debugProp);
    props.group && props.untilMeasured === "hide" && !curStateRef.hasMeasured && (splitStyles.style ||= {}, splitStyles.style.opacity = 0), process.env.NODE_ENV === "development" && time && time`split-styles`, curStateRef.isListeningToTheme = splitStyles.dynamicThemeAccess;
    const isMediaArray = splitStyles.hasMedia && Array.isArray(splitStyles.hasMedia),
      shouldListenForMedia = didGetVariableValue() || isMediaArray || noClassNames && splitStyles.hasMedia === !0,
      mediaListeningKeys = isMediaArray ? splitStyles.hasMedia : null;
    setMediaShouldUpdate(stateRef, {
      enabled: shouldListenForMedia,
      keys: mediaListeningKeys
    });
    const {
        viewProps: viewPropsIn,
        pseudos,
        style: splitStylesStyle,
        classNames,
        space
      } = splitStyles,
      propsWithAnimation = props,
      {
        asChild,
        children,
        themeShallow,
        spaceDirection: _spaceDirection,
        onPress,
        onLongPress,
        onPressIn,
        onPressOut,
        onHoverIn,
        onHoverOut,
        onMouseUp,
        onMouseDown,
        onMouseEnter,
        onMouseLeave,
        onFocus,
        onBlur,
        separator,
        // ignore from here on out
        forceStyle: _forceStyle,
        // @ts-ignore  for next/link compat etc
        onClick,
        theme: _themeProp,
        // @ts-ignore
        defaultVariants,
        ...nonTamaguiProps
      } = viewPropsIn;
    let viewProps = nonTamaguiProps;
    !isTaggable && props.forceStyle && (viewProps.forceStyle = props.forceStyle), isHOC && _themeProp && (viewProps.theme = _themeProp), elementType.acceptTagProp && (viewProps.tag = tagProp);
    let animationStyles;
    if (
    // if it supports css vars we run it on server too to get matching initial style
    (supportsCSSVars ? willBeAnimatedClient : willBeAnimated) && useAnimations && !isHOC) {
      const animations = useAnimations({
        props: propsWithAnimation,
        // if hydrating, send empty style
        style: splitStylesStyle || {},
        presence,
        componentState: state,
        styleProps,
        theme: themeState.state?.theme,
        pseudos: pseudos || null,
        staticConfig,
        stateRef
      });
      (isAnimated || supportsCSSVars) && animations && (animationStyles = animations.style, viewProps.style = animationStyles), process.env.NODE_ENV === "development" && time && time`animations`;
    }
    process.env.NODE_ENV === "development" && props.untilMeasured && !props.group && console.warn(`You set the untilMeasured prop without setting group. This doesn't work, be sure to set untilMeasured on the parent that sets group, not the children that use the $group- prop.

If you meant to do this, you can disable this warning - either change untilMeasured and group at the same time, or do group={conditional ? 'name' : undefined}`), process.env.NODE_ENV === "development" && time && time`destructure`, groupName && (nonTamaguiProps.onLayout = composeEventHandlers(nonTamaguiProps.onLayout, e => {
      stateRef.current.group.emit(groupName, {
        layout: e.nativeEvent.layout
      }), !stateRef.current.hasMeasured && props.untilMeasured === "hide" && setState(prev => ({
        ...prev
      })), stateRef.current.hasMeasured = !0;
    })), viewProps = hooks.usePropsTransform?.(elementType, nonTamaguiProps, stateRef, curStateRef.willHydrate) || nonTamaguiProps, curStateRef.composedRef || (curStateRef.composedRef = composeRefs(x => stateRef.current.host = x, forwardedRef)), viewProps.ref = curStateRef.composedRef, process.env.NODE_ENV === "development" && !isReactNative && !isText && isWeb && !isHOC && Children.toArray(props.children).forEach(item => {
      typeof item == "string" && item !== `
` && console.error(`Unexpected text node: ${item}. A text node cannot be a child of a <View>.`);
    }), process.env.NODE_ENV === "development" && time && time`events-hooks`;
    const {
      pseudoGroups,
      mediaGroups
    } = splitStyles;
    curStateRef.unPress || (curStateRef.unPress = () => setStateShallow({
      press: !1,
      pressIn: !1
    }));
    const unPress = curStateRef.unPress;
    useEffect(() => {
      if (disabled) return;
      if (state.unmounted) {
        setStateShallow({
          unmounted: !1
        });
        return;
      }
      const dispose = subscribeToContextGroup({
        disabled,
        componentContext,
        setStateShallow,
        state,
        mediaGroups,
        pseudoGroups
      });
      return () => {
        dispose?.(), mouseUps.delete(unPress);
      };
    }, [disabled, pseudoGroups ? Object.keys([...pseudoGroups]).join("") : 0, mediaGroups ? Object.keys([...mediaGroups]).join("") : 0]);
    const runtimePressStyle = !disabled && noClassNames && pseudos?.pressStyle,
      runtimeFocusStyle = !disabled && noClassNames && pseudos?.focusStyle,
      runtimeFocusVisibleStyle = !disabled && noClassNames && pseudos?.focusVisibleStyle,
      attachFocus = !!(runtimePressStyle || runtimeFocusStyle || runtimeFocusVisibleStyle || onFocus || onBlur),
      attachPress = !!(groupName || runtimePressStyle || onPress || onPressOut || onPressIn || onMouseDown || onMouseUp || onLongPress || onClick || pseudos?.focusVisibleStyle),
      runtimeHoverStyle = !disabled && noClassNames && pseudos?.hoverStyle,
      needsHoverState = !!(groupName || runtimeHoverStyle || onHoverIn || onHoverOut),
      attachHover = isWeb && !!(groupName || needsHoverState || onMouseEnter || onMouseLeave),
      shouldAttach = !disabled && !props.asChild && !!(attachFocus || attachPress || attachHover || runtimePressStyle || runtimeHoverStyle || runtimeFocusStyle),
      needsPressState = !!(groupName || runtimePressStyle);
    process.env.NODE_ENV === "development" && time && time`events-setup`;
    const events = shouldAttach ? {
      onPressOut: attachPress ? e => {
        unPress(), onPressOut?.(e), onMouseUp?.(e);
      } : void 0,
      ...((attachHover || attachPress) && {
        onMouseEnter: e => {
          const next = {};
          needsHoverState && (next.hover = !0), needsPressState && state.pressIn && (next.press = !0), setStateShallow(next), onHoverIn?.(e), onMouseEnter?.(e);
        },
        onMouseLeave: e => {
          const next = {};
          mouseUps.add(unPress), needsHoverState && (next.hover = !1), needsPressState && state.pressIn && (next.press = !1, next.pressIn = !1), setStateShallow(next), onHoverOut?.(e), onMouseLeave?.(e);
        }
      }),
      onPressIn: attachPress ? e => {
        (runtimePressStyle || groupName) && setStateShallow({
          press: !0,
          pressIn: !0
        }), onPressIn?.(e), onMouseDown?.(e), isWeb && mouseUps.add(unPress);
      } : void 0,
      onPress: attachPress ? e => {
        unPress(), isWeb && onClick?.(e), onPress?.(e), onLongPress?.(e);
      } : void 0,
      ...(attachFocus && {
        onFocus: e => {
          pseudos?.focusVisibleStyle ? setTimeout(() => {
            setStateShallow({
              focus: !0,
              focusVisible: !!lastInteractionWasKeyboard.value
            });
          }, 0) : setStateShallow({
            focus: !0,
            focusVisible: !1
          }), onFocus?.(e);
        },
        onBlur: e => {
          setStateShallow({
            focus: !1,
            focusVisible: !1
          }), onBlur?.(e);
        }
      })
    } : null;
    events && !isReactNative && Object.assign(viewProps, getWebEvents(events)), process.env.NODE_ENV === "development" && time && time`events`, process.env.NODE_ENV === "development" && debugProp === "verbose" && log("events", {
      events,
      attachHover,
      attachPress
    }), hooks.useEvents?.(viewProps, events, splitStyles, setStateShallow, staticConfig);
    const direction = props.spaceDirection || "both";
    process.env.NODE_ENV === "development" && time && time`hooks`;
    let content = !children || asChild ? children : spacedChildren({
      separator,
      children,
      space,
      direction,
      isZStack,
      debug: debugProp
    });
    if (asChild) {
      elementType = Slot;
      {
        const passEvents = getWebEvents({
          onPress,
          onLongPress,
          onPressIn,
          onPressOut,
          onMouseUp,
          onMouseDown,
          onMouseEnter,
          onMouseLeave
        }, asChild === "web" || asChild === "except-style-web");
        Object.assign(viewProps, passEvents);
      }
    }
    process.env.NODE_ENV === "development" && time && time`spaced-as-child`;
    let useChildrenResult;
    hooks.useChildren && (useChildrenResult = hooks.useChildren(elementType, content, viewProps, events, staticConfig)), useChildrenResult ? content = useChildrenResult : content = createElement(elementType, viewProps, content);
    const ResetPresence = config?.animations?.ResetPresence;
    ResetPresence && willBeAnimated && (hasEnterStyle || presenceState) && content && typeof content != "string" && (content = /* @__PURE__ */jsx(ResetPresence, {
      children: content
    })), process.env.NODE_ENV === "development" && time && time`create-element`;
    const groupState = curStateRef.group,
      subGroupContext = useMemo(() => {
        if (!(!groupState || !groupName)) return groupState.listeners.clear(), {
          ...componentContext.groups,
          // change reference so as we mutate it doesn't affect siblings etc
          state: {
            ...componentContext.groups.state,
            [groupName]: {
              pseudo: defaultComponentStateMounted,
              // capture just initial width and height if they exist
              // will have top, left, width, height (not x, y)
              layout: {
                width: fromPx(splitStyles.style?.width),
                height: fromPx(splitStyles.style?.height)
              }
            }
          },
          emit: groupState.emit,
          subscribe: groupState.subscribe
        };
      }, [groupName]);
    if (groupName && subGroupContext && (content = /* @__PURE__ */jsx(ComponentContext.Provider, {
      ...componentContext,
      groups: subGroupContext,
      children: content
    })), process.env.NODE_ENV === "development" && time && time`group-context`, content = disableTheme ? content : getThemedChildren(themeState, content, themeStateProps, !1), process.env.NODE_ENV === "development" && time && time`themed-children`, process.env.NODE_ENV === "development" && props.debug === "visualize" && (content = /* @__PURE__ */jsx(ThemeDebug, {
      themeState,
      themeProps: props,
      children: content
    })), isReactNative && !asChild && (content = /* @__PURE__ */jsx("span", {
      className: "_dsp_contents",
      ...(isHydrated && events && getWebEvents(events)),
      children: content
    })), staticConfig.context) {
      const contextProps = staticConfig.context.props;
      for (const key in contextProps) (viewProps.style && key in viewProps.style || key in viewProps) && (overriddenContextProps ||= {}, overriddenContextProps[key] = viewProps.style?.[key] ?? viewProps[key]);
    }
    if (overriddenContextProps) {
      const Provider = staticConfig.context.Provider;
      content = /* @__PURE__ */jsx(Provider, {
        ...contextValue,
        ...overriddenContextProps,
        children: content
      });
    }
    if (process.env.NODE_ENV === "development" && debugProp && debugProp !== "profile") {
      const title = `render <${typeof elementType == "string" ? elementType : "Component"} /> (${internalID}) with props`;
      if (isWeb) {
        console.groupCollapsed(title);
        try {
          log("viewProps", viewProps), log("children", content), typeof window < "u" && (log("props in", propsIn, "mapped to", props, "in order", Object.keys(props)), log({
            animationStyles,
            classNames,
            content,
            defaultProps,
            elementType,
            events,
            isAnimated,
            isMediaArray,
            isStringElement,
            mediaListeningKeys,
            pseudos,
            shouldAttach,
            shouldAvoidClasses,
            shouldForcePseudo,
            shouldListenForMedia,
            splitStyles,
            splitStylesStyle,
            state,
            stateRef,
            staticConfig,
            styleProps,
            tamaguiConfig,
            themeState,
            viewProps,
            willBeAnimated
          }));
        } catch {} finally {
          console.groupEnd();
        }
      } else {
        log(title), log("state: ", state), isDevTools && log("viewProps", viewProps), log("final styles:");
        for (const key in splitStylesStyle) log(key, splitStylesStyle[key]);
      }
      if (debugProp === "break") debugger;
    }
    return process.env.NODE_ENV === "development" && time && (time`rest`, globalThis.willPrint || (globalThis.willPrint = !0, setTimeout(() => {
      delete globalThis.willPrint, time.print(), time = null;
    }, 50))), content;
  });
  staticConfig.componentName && (component.displayName = staticConfig.componentName);
  let res = component;
  (process.env.TAMAGUI_FORCE_MEMO || staticConfig.memo) && (res = memo(res)), res.staticConfig = staticConfig;
  function extendStyledConfig(extended) {
    return {
      ...staticConfig,
      ...extended,
      neverFlatten: !0,
      isHOC: !0,
      isStyledHOC: !1
    };
  }
  function extractable(Component2, extended) {
    return Component2.staticConfig = extendStyledConfig(extended), Component2.styleable = styleable, Component2;
  }
  function styleable(Component2, options) {
    let out = Component2.render?.length === 2 ? Component2 : forwardRef(Component2);
    const extendedConfig = extendStyledConfig(options?.staticConfig);
    return out = options?.disableTheme ? out : themeable(out, extendedConfig), process.env.TAMAGUI_MEMOIZE_STYLEABLE && (out = memo(out)), out.staticConfig = extendedConfig, out.styleable = styleable, out;
  }
  return res.extractable = extractable, res.styleable = styleable, res;
}
function getWebEvents(events, webStyle = !0) {
  return {
    onMouseEnter: events.onMouseEnter,
    onMouseLeave: events.onMouseLeave,
    [webStyle ? "onClick" : "onPress"]: events.onPress,
    onMouseDown: events.onPressIn,
    onMouseUp: events.onPressOut,
    onTouchStart: events.onPressIn,
    onTouchEnd: events.onPressOut,
    onFocus: events.onFocus,
    onBlur: events.onBlur
  };
}
function Unspaced(props) {
  return props.children;
}
Unspaced.isUnspaced = !0;
const getSpacerSize = (size, {
    tokens
  }) => {
    size = size === !0 ? "$true" : size;
    const sizePx = tokens.space[size] ?? size;
    return {
      width: sizePx,
      height: sizePx,
      minWidth: sizePx,
      minHeight: sizePx
    };
  },
  Spacer = createComponent({
    acceptsClassName: !0,
    memo: !0,
    componentName: "Spacer",
    validStyles,
    defaultProps: {
      ...stackDefaultStyles,
      // avoid nesting issues
      tag: "span",
      size: !0,
      pointerEvents: "none"
    },
    variants: {
      size: {
        "...": getSpacerSize
      },
      flex: {
        true: {
          flexGrow: 1
        }
      },
      direction: {
        horizontal: {
          height: 0,
          minHeight: 0
        },
        vertical: {
          width: 0,
          minWidth: 0
        },
        both: {}
      }
    }
  });
function spacedChildren(props) {
  const {
      isZStack,
      children,
      space,
      direction,
      spaceFlex,
      separator
    } = props,
    hasSpace = !!(space || spaceFlex),
    hasSeparator = separator != null;
  if (!(hasSpace || hasSeparator || isZStack)) return children;
  const childrenList = Children.toArray(children);
  if (childrenList.length <= 1 && !isZStack && !childrenList[0]?.type?.shouldForwardSpace) return childrenList;
  const final = [];
  for (let [index, child] of childrenList.entries()) {
    const isEmpty = child == null || Array.isArray(child) && child.length === 0;
    if (!isEmpty && React.isValidElement(child) && child.type?.shouldForwardSpace && (child = React.cloneElement(child, {
      space,
      spaceFlex,
      separator,
      key: child.key
    })), isEmpty || !child || child.key && !isZStack ? final.push(child) : final.push( /* @__PURE__ */jsx(Fragment, {
      children: isZStack ? /* @__PURE__ */jsx(AbsoluteFill, {
        children: child
      }) : child
    }, index)), isUnspaced(child) && index === 0 || isZStack) continue;
    const next = childrenList[index + 1];
    next && !isUnspaced(next) && (separator ? (hasSpace && final.push(createSpacer({
      key: `_${index}_00tmgui`,
      direction,
      space,
      spaceFlex
    })), final.push(React.isValidElement(separator) ? React.cloneElement(separator, {
      key: `sep_${index}`
    }) : separator), hasSpace && final.push(createSpacer({
      key: `_${index}01tmgui`,
      direction,
      space,
      spaceFlex
    }))) : final.push(createSpacer({
      key: `_${index}02tmgui`,
      direction,
      space,
      spaceFlex
    })));
  }
  return process.env.NODE_ENV === "development" && props.debug && log("  Spaced children", final, props), final;
}
function createSpacer({
  key,
  direction,
  space,
  spaceFlex
}) {
  return /* @__PURE__ */jsx(Spacer, {
    size: space,
    direction,
    ...(typeof spaceFlex < "u" && {
      flex: spaceFlex === !0 ? 1 : spaceFlex === !1 ? 0 : spaceFlex
    })
  }, key);
}
function isUnspaced(child) {
  const t = child?.type;
  return t?.isVisuallyHidden || t?.isUnspaced;
}
const AbsoluteFill = createComponent({
  defaultProps: {
    ...stackDefaultStyles,
    flexDirection: "column",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    pointerEvents: "box-none"
  }
});
function hasAnimatedStyleValue(style) {
  return Object.keys(style).some(k => {
    const val = style[k];
    return val && typeof val == "object" && "_animation" in val;
  });
}
function getMediaState(mediaGroups, layout) {
  return Object.fromEntries([...mediaGroups].map(mediaKey => [mediaKey, mediaKeyMatch(mediaKey, layout)]));
}
const fromPx = val => typeof val != "string" ? val : +val.replace("px", ""),
  isDisabled = props => props.disabled || props.accessibilityState?.disabled || props["aria-disabled"] || props.accessibilityDisabled || !1,
  subscribeToContextGroup = ({
    disabled = !1,
    setStateShallow,
    pseudoGroups,
    mediaGroups,
    componentContext,
    state
  }) => {
    if (pseudoGroups || mediaGroups) {
      const current = {
        pseudo: {},
        media: {}
      };
      return process.env.NODE_ENV === "development" && !componentContext.groups && console.debug("No context group found"), componentContext.groups?.subscribe((name, {
        layout,
        pseudo
      }) => {
        if (pseudo && pseudoGroups?.has(String(name))) Object.assign(current.pseudo, pseudo), persist();else if (layout && mediaGroups) {
          const mediaState = getMediaState(mediaGroups, layout),
            next = mergeIfNotShallowEqual(current.media, mediaState);
          next !== current.media && (Object.assign(current.media, next), persist());
        }
        function persist() {
          const group = {
            ...state.group,
            [name]: current
          };
          setStateShallow({
            group
          });
        }
      });
    }
  };
export { Spacer, Unspaced, createComponent, isDisabled, mouseUps, spacedChildren, subscribeToContextGroup, useComponentState };