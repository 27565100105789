var BindingScopeEnum = {
    Request: 'Request',
    Singleton: 'Singleton',
    Transient: 'Transient'
};
var BindingTypeEnum = {
    ConstantValue: 'ConstantValue',
    Constructor: 'Constructor',
    DynamicValue: 'DynamicValue',
    Factory: 'Factory',
    Function: 'Function',
    Instance: 'Instance',
    Invalid: 'Invalid',
    Provider: 'Provider'
};
var TargetTypeEnum = {
    ClassProperty: 'ClassProperty',
    ConstructorArgument: 'ConstructorArgument',
    Variable: 'Variable'
};
export { BindingScopeEnum, BindingTypeEnum, TargetTypeEnum };
